import { select2Matcher, select2Empty } from '../helper/select2';
import { enabledButtonForm, disabledButtonForm } from '../helper/validate_form';

onLoad(($) => {
  const $formInterestsAndDiscounts = $('.JS-form-rates-and-discounts').closest('form');
  if (!$formInterestsAndDiscounts.length) return;

  const $radioChargeGroupRegistered = $('#charge-group-registered');
  const $listChargeGroupInfo = $('.JS-list-charge-group-info');
  const $radioChargeSpecificConfig = $('#charge-specific-config');
  const $selectChargeInterestTypeCd = $('#charge_interest_type_cd');
  const $inputChargeMonthlyInterest = $('#charge_monthly_interest');
  const $inputApplicableFine = $('#charge_formatted_applicable_fine');
  const $selectChargeGroup = () => $('#charge_charge_group');
  const $listDiscounts = () => $('.JS-list-charge-group-info-discounts');
  const $inputsRequired = $wrap => $wrap
    .find($('input[required="required"], select[required="required"]'));
  const $wrapChargeGroupRegistered = $('.JS-charge-group-registered-wrap');
  const $inputsRequiredChargeGroupRegistered = $inputsRequired($wrapChargeGroupRegistered);
  const $wrapChargeSpecificConfig = $('.JS-charge-specific-config-wrap');
  const $inputsRequiredChargeSpecificConfig = $inputsRequired($wrapChargeSpecificConfig);
  const $buttonSave = $('.JS-button-save');
  const $cardAbatement = $('.JS-card-abatement');
  const $inputAbatement = $('.JS-input-abatement');
  const $inputChargeDaysWriteOffAfterDueDate = $('#charge_days_write_off_after_due_date');
  const isChargeGroupSelected = $selectChargeGroup().data('is-charge-group-selected');
  const $inputsDiscountValue = () => $('.charge_discounts_discount_value input');
  const sanitizeValue = (_, value) => value.replace('.', '').replace(',', '.');
  const MIN_DISCOUNT_DAY = 1;

  const templateDiscounts = (discount) => {
    const composeDiscountDaysBeforeDueDate = () => {
      if (!discount.days_before_due_date) return '-';
      return `${discount.days_before_due_date} dia${
        discount.days_before_due_date > MIN_DISCOUNT_DAY ? 's' : ''} antes do vencimento`;
    };

    return `<div class="d-flex flex-column flex-md-row mt-3">
        <li class="mt-2 mt-md-0">
          <div class="item-info-charge-group">
            <strong>Modelo de desconto</strong>
          </div>
          <span>${discount.translated_discount_type || '-'}</span>
        </li>
        <li class="mt-2 mt-md-0">
          <div class="item-info-charge-group">
            <strong>Desconto (%)</strong>
          </div>
          <span>${discount.value.replace('.', ',') || '-'}</span>
        </li>
        <li class="mt-2 mt-md-0">
          <div class="item-info-charge-group">
            <strong>Limite para aplicar desconto</strong>
          </div>
          <span>${composeDiscountDaysBeforeDueDate()}</span>
        </li>
      </div>
    `;
  };

  const templateSubtitleWithouDiscounts = `
    <div class="subtitle mt-4">
      Este Grupo de Cobrança não possui faixas de desconto configuradas. 
      Para adicionar até 3 faixas de desconto, edite o Grupo.
    </div>
  `;

  const setDataChargeGroup = (data) => {
    const isDiscounts = data.discounts.length;
    $listDiscounts().html('');
    $listChargeGroupInfo.find('#chargeGroupInterestType')
      .text(`${data.translated_interest_type || '-'}`);
    $listChargeGroupInfo.find('#chargeGroupMonthlyInterest')
      .text(`${data.formatted_monthly_interest || '-'}`);
    $listChargeGroupInfo.find('#chargeGroupFormattedApplicableFine')
      .text(`${data.formatted_applicable_fine ? data.formatted_applicable_fine.replace('.', ',') : '-'}`);
    $listChargeGroupInfo.find('#chargeGroupDaysWriteOffAfterDueDate')
      .text(`${data.days_write_off_after_due_date || '-'}`);
    if (!isDiscounts) {
      $listDiscounts().append(templateSubtitleWithouDiscounts);
      return;
    }

    data.discounts.forEach((discount) => {
      $listDiscounts().append(templateDiscounts(discount));
    });
  };

  function handleChargeGroupError() {
    $selectChargeGroup().val('');
    disabledButtonForm($buttonSave);
    $listChargeGroupInfo.addClass('d-none');
    $cardAbatement.addClass('d-none');
  }

  const findChargeGroup = (id) => {
    if (!id) {
      handleChargeGroupError();
      return;
    }

    $.ajax({
      url: `/charge_groups/${id}`,
      type: 'get',
      dataType: 'json',
      success: (data) => {
        setDataChargeGroup(data);
        $listChargeGroupInfo.removeClass('d-none');
        $cardAbatement.removeClass('d-none');
        enabledButtonForm($buttonSave);
      },
      error: () => { handleChargeGroupError(); },
    });
  };

  const removeRequired = ($inputsRemoveRequired) => {
    $inputsRemoveRequired.each(function () {
      $(this).removeAttr('required');
    });
  };

  const addRequired = ($inputsAddRequired) => {
    $inputsAddRequired.each(function () {
      $(this).attr('required', 'required');
    });
    $inputsAddRequired.trigger('change');
  };

  const handleWrap = ({ $wrap, $inputsRemoveRequired, $inputsAddRequired }) => {
    removeRequired($inputsRemoveRequired);
    addRequired($inputsAddRequired);
    $wrap.prev().addClass('d-none');
    $wrap.next().addClass('d-none');
    $wrap.removeClass('d-none');
  };

  const handleRadio = () => {
    const radioTrigger = ($radio) => {
      $radio.prop('checked', true);
      $radio.trigger('change');
    };

    if (isChargeGroupSelected) radioTrigger($radioChargeGroupRegistered);
    else if ($inputChargeDaysWriteOffAfterDueDate.val()) radioTrigger($radioChargeSpecificConfig);
  };

  const handleSubmit = (event, element) => {
    event.preventDefault();

    $inputChargeMonthlyInterest.val(sanitizeValue);
    $inputsDiscountValue().each(function () {
      $(this).val(sanitizeValue);
    });

    if ($wrapChargeSpecificConfig.hasClass('d-none')) {
      $inputAbatement.last().remove();
      $selectChargeInterestTypeCd.val('');
      $inputChargeMonthlyInterest.val('');
      $inputApplicableFine.val('');
      $inputChargeDaysWriteOffAfterDueDate.val('');
      $('.JS-card-discounts').remove();
    }

    if ($wrapChargeGroupRegistered.hasClass('d-none')) {
      $inputAbatement.first().remove();
      $selectChargeGroup().val('');
    }

    element.submit();
  };

  $selectChargeGroup().on('change', function () {
    findChargeGroup($(this).val());
  });

  $selectChargeInterestTypeCd.on('change', function () {
    const isEmpty = !$(this).val();
    $inputChargeMonthlyInterest[`${isEmpty ? 'removeAttr' : 'attr'}`]('required', 'required');
  });

  $radioChargeGroupRegistered.on('change', () => {
    handleWrap({
      $wrap: $wrapChargeGroupRegistered,
      $inputsRemoveRequired: $inputsRequiredChargeSpecificConfig,
      $inputsAddRequired: $inputsRequiredChargeGroupRegistered,
    });
    findChargeGroup($selectChargeGroup().val());
  });

  $radioChargeSpecificConfig.on('change', () => {
    $inputsRequiredChargeSpecificConfig.trigger('change');
    handleWrap({
      $wrap: $wrapChargeSpecificConfig,
      $inputsRemoveRequired: $inputsRequiredChargeGroupRegistered,
      $inputsAddRequired: $inputsRequiredChargeSpecificConfig,
    });
  });

  $formInterestsAndDiscounts.on('submit', function (event) {
    handleSubmit(event, this);
  });

  handleRadio();

  $(document).ready(() => {
    select2Matcher($selectChargeGroup());
    select2Empty($selectChargeInterestTypeCd);
  });
});
