import { CONTEXT } from '../helpers/navigation_helper';

const initUserRoleTable = ({
  $,
  context,
  currentSelection: userAccounts,
  selectOptions: { roles: roleOptions, legacyRoles: legacyRolesOptions },
}) => {
  const tableContainerSelector = '.JS-invitation-roles-table';

  const $tableContainer = $(tableContainerSelector);

  if (!$tableContainer.length) return undefined;

  const parentContainerSelector = '.JS-invitation';

  const $parentContainer = $(parentContainerSelector);
  const $footer = $parentContainer.find('.JS-table-footer');
  const $batchActioncounter = $('.JS-client-users-roles-batch-action-counter');

  let $userRolesTable = null;
  let selectedIpBankAccounts = userAccounts;
  let currentSelectedRoles = [];
  const destroyedRoles = [];

  // #region Private Functions
  const findCurrentSelectedRolesById = (id) => {
    const findRole = selectedRole => selectedRole.ipBankAccountId === id;

    const role = currentSelectedRoles.find(findRole);

    return role || null;
  };

  const setSelectValueOnCurrentPage = () => {
    const $selects = $tableContainer.find('table tbody select');

    $selects.each((_, select) => {
      const $select = $(select);
      const clientId = $select.data('client-id');
      const role = findCurrentSelectedRolesById(clientId);
      const originalId = $select.data('original-id');

      if (role && role.id === originalId) $select.val(role.roleId);
    });
  };

  const getSelectedRowsCount = () => {
    if (!$userRolesTable) return null;

    return $userRolesTable.getSelection().length;
  };

  const updateBatchActionCounter = () => {
    const count = getSelectedRowsCount();

    $batchActioncounter.html(count);
  };

  const setFooterVisibility = (canShow) => {
    const isFooterVisible = $footer.is(':visible');

    if (isFooterVisible && canShow) return;

    if (canShow) $footer.show(400);
    else $footer.hide();
  };

  const removeAccountFromListsById = (id) => {
    const filterList = item => item.ipBankAccountId !== id;

    currentSelectedRoles = currentSelectedRoles.filter(filterList);
    selectedIpBankAccounts = selectedIpBankAccounts.filter(filterList);
  };

  const findCurrentSelectedRolesIndexById = (id) => {
    const findItem = item => item.ipBankAccountId === id;

    return currentSelectedRoles.findIndex(findItem);
  };

  const canSetRoleValue = (id) => {
    const role = selectedIpBankAccounts.find(account => account.id === id);

    if (!role) return true;

    if (role.isKindLegacy) return false;

    return true;
  };

  const handleSelectedUserRoles = ({ ipBankAccountId, id, roleId }) => {
    const canSetValue = canSetRoleValue(id);

    if (!canSetValue) return;

    const roleIndex = findCurrentSelectedRolesIndexById(ipBankAccountId);

    if (roleIndex >= 0) {
      currentSelectedRoles[roleIndex].roleId = roleId;
      return;
    }

    if (roleId === '') {
      const filterList = item => item.ipBankAccountId !== ipBankAccountId;

      currentSelectedRoles = currentSelectedRoles.filter(filterList);
      return;
    }

    currentSelectedRoles.push({ ipBankAccountId, id, roleId });
  };

  const setSelectValueOfCurrentCheckedRows = (roleId) => {
    const $checkboxes = $parentContainer.find("input[type='checkbox']");
    const $filteredCheckboxes = $checkboxes.filter(':checked');

    $filteredCheckboxes.each((_, checkbox) => {
      const $checkbox = $(checkbox);
      const $select = $checkbox.closest('tr').find('select').not(':disabled');

      if ($select.length) $select.val(roleId).trigger('change');
    });
  };

  const markToDestroy = (id) => {
    const findIndex = item => item.id === id;
    const index = currentSelectedRoles.findIndex(findIndex);

    if (index < 0) return;

    const role = currentSelectedRoles[index];

    if (!role) return;

    const destroyKey = '_destroy';

    role[destroyKey] = true;

    destroyedRoles.push(role);
  };
  // #endregion Private Functions

  // #region Render Table Component Functions
  const getRolesOptionsForSelect = (row) => {
    const kind = row.kind_cd;
    const isKindLegacy = row.isKindLegacy;

    const mapRoles = ({ label, value }) => {
      const selected = value === kind ? 'selected=""' : '';

      return `<option value="${value}" ${selected}>${label}</option>`;
    };

    const options = isKindLegacy ? legacyRolesOptions : roleOptions;
    const mappedOptions = options.map(mapRoles);

    return mappedOptions.join('');
  };

  const buildSelectComponent = (row) => {
    const { ipBankAccountId, isKindLegacy, id } = row;
    const name = `role-account-${ipBankAccountId}`;
    const selectId = `role-${ipBankAccountId}`;

    return `
      <select
        class="form-control"
        data-client-id="${ipBankAccountId}"
        ${id ? `data-original-id="${id}"` : ''}
        ${isKindLegacy ? 'disabled' : ''}
        id="${selectId}"
        name="${name}">
          <option value>Selecione uma opção...</option>
          ${getRolesOptionsForSelect(row)}
      </select>
      <p class="mb-0 invalid-feedback"></p>
    `;
  };

  const renderDisplayName = (row) => {
    if (row.name) {
      const {
        account_number: accountNumber, agency, kind, name,
      } = row;
      return `${name} - Agência: ${agency} | Conta: ${accountNumber} (${kind.translated})`;
    }
    return row.account_number;
  };

  const buildSelectWithTooltip = (row) => {
    const id = row.id;
    const selectHtml = buildSelectComponent(row);

    return `
      <span
        class="deprecated-role-tooltip role-tooltip-${id}"
        data-container=".role-tooltip-${id}"
        data-html="true"
        data-placement="top"
        data-toggle="popover"
        data-trigger="hover"
        tabindex="0"
        data-content="Este perfil está ativo, mas restrito às regras já criadas.
          Não é mais possível criar ou editar novas regras utilizando este perfil.
          Para alterar o perfil desta conta, exclua a regra existente e adicione novamente com um perfil atualizado.">
        ${selectHtml}
      </span>
      `;
  };

  const getRolePopoverHtml = () => `
      <span class="mr-1">Perfil</span>
      <span
        class="roles-tooltip"
        data-container=".roles-tooltip"
        data-html="true"
        data-placement="top"
        data-toggle="popover"
        data-trigger="hover"
        tabindex="0"
        data-content="Os perfis serão aplicados conforme o papel da empresa na conta.
          <a href='https://meajuda.grafeno.digital/por-que-uma-funcionalidade-nao-aparece' target='_blank'>
          <b>Saiba mais</b></a>."
        >
        <i class="fas fa-exclamation-circle"></i>
      </span>
    `;

  const setRoleHeaderPopover = () => {
    const popoverHtml = getRolePopoverHtml();

    $tableContainer
      .find('table th:contains("Perfil")')
      .replaceWith(`<th>${popoverHtml}</th>`);

    const $popoverComponent = $tableContainer.find('.roles-tooltip');

    if (!$popoverComponent.length) return;

    const options = { trigger: 'hover' };

    $popoverComponent.popover(options);
  };

  const setDeprecatedRolePopover = () => {
    const $tooltips = $tableContainer.find('.deprecated-role-tooltip');

    if (!$tooltips.length) return;

    $tooltips.popover({ trigger: 'hover' });
  };

  const buildDeleteButton = (row) => {
    const { ipBankAccountId } = row;
    const butonSelectorClass = 'JS-user-roles-remove-association';

    return `
      <button
        class="btn btn-danger ${butonSelectorClass}"
        type="button"
        data-client-id="${ipBankAccountId}">
        <i class="fas fa-trash"></i>
      </button>
    `;
  };
  // #endregion Render Table Component Functions

  // #region Init Table
  const initTable = () => {
    currentSelectedRoles = [];

    const chooseSelect = row => (row.isKindLegacy
      ? buildSelectWithTooltip(row)
      : buildSelectComponent(row));

    const idKey = context === CONTEXT.invite ? 'ipBankAccountId' : 'id';

    const tableOptions = {
      columns: ['Conta', 'CNPJ', 'Perfil', 'Acões'],
      startPage: 1,
      idKey,
      modelKeys: [
        { key: idKey },
        { key: 'account_number', render: renderDisplayName },
        { key: 'document_number' },
        { key: 'role', render: chooseSelect },
        { key: 'actions', render: buildDeleteButton },
      ],
      rows: selectedIpBankAccounts,
      selectorPrefix: '.JS-invitation',
      tablePageSize: 10,
    };

    $userRolesTable = $tableContainer.paginatedTable(tableOptions);

    $userRolesTable.setPageChangeCallback(setSelectValueOnCurrentPage);

    setRoleHeaderPopover();
    setDeprecatedRolePopover();
  };
  // #endregion Init Table

  // #region Event Handler Functions
  const onRemoveRole = (e) => {
    const $button = $(e.currentTarget);

    if (!$button.length) return;

    const id = $button.data('client-id');
    const $select = $button.parent().siblings().find('select');
    const originalId = $select.data('original-id');

    if (originalId) markToDestroy(originalId);

    removeAccountFromListsById(id);

    const filterSelection = selected => selected.ipBankAccountId !== id;
    const mapSelection = selected => selected.ipBankAccountId;

    const currentSelection = $userRolesTable.getSelection();
    const filteredIds = currentSelection.filter(filterSelection);
    const mappedIds = filteredIds.map(mapSelection);

    $userRolesTable.updateRows(selectedIpBankAccounts);
    $userRolesTable.setSelection(mappedIds);

    setSelectValueOnCurrentPage();
    updateBatchActionCounter();
  };

  const onCheckBoxChange = () => {
    const currentSelection = $userRolesTable.getSelection();
    const canShowFooter = currentSelection.length > 0;

    setFooterVisibility(canShowFooter);
    updateBatchActionCounter();
  };

  const onChangeRoleSelect = (e) => {
    const $select = $(e.currentTarget);

    if (!$select.length) return;

    const roleId = $select.val();
    const clientId = $select.data('client-id');
    const originalId = $select.data('original-id');

    handleSelectedUserRoles({ ipBankAccountId: clientId, id: originalId, roleId });
  };

  const onHandleBatchAction = () => {
    const $role = $parentContainer.find('.JS-table-footer select');

    if (!$role.length) return;

    const roleId = $role.val();

    setSelectValueOfCurrentCheckedRows(roleId);

    const currentSelection = $userRolesTable.getSelection();

    currentSelection.forEach(selected => handleSelectedUserRoles({
      ipBankAccountId: selected.ipBankAccountId,
      id: selected.id,
      roleId,
    }));
  };

  const onCancelBatchAction = () => {
    const $tableFooter = $parentContainer.find('.JS-table-footer');

    if (!$tableFooter.length) return;

    $tableFooter.hide();
  };
  // #endregion Event Handler Functions

  // #region Event Handlers
  const initClickEventHandler = () => {
    $tableContainer.on(
      'click',
      '.JS-user-roles-remove-association',
      onRemoveRole,
    );
    $parentContainer.on(
      'click',
      '#JS-client-users-roles-batch-action-apply',
      onHandleBatchAction,
    );
    $parentContainer.on(
      'click',
      '.JS-client-users-roles-cancel-batch-action',
      onCancelBatchAction,
    );
  };

  const initChangeEventHandler = () => {
    $tableContainer.on(
      'change',
      "input[type='checkbox']",
      onCheckBoxChange,
    );
    $tableContainer.on(
      'click',
      '.select-all-rows-toggle .toggle, .select-all-rows-toggle .undo-selection',
      onCheckBoxChange,
    );
    $tableContainer.on(
      'change',
      'select',
      onChangeRoleSelect,
    );
  };
  // #endregion Event Handlers

  // #region Public Methods
  const onAddNewAccounts = (newAccounts = []) => {
    selectedIpBankAccounts = selectedIpBankAccounts.concat(newAccounts);
    $userRolesTable.updateRows(selectedIpBankAccounts);
  };

  const onSetSelectedRoles = (newSelectedRoles = []) => {
    currentSelectedRoles = newSelectedRoles;
  };

  const onGetRoles = () => {
    const currentRoles = currentSelectedRoles.concat(destroyedRoles);

    return currentRoles;
  };

  const METHODS = Object.freeze({
    addNewAccounts: onAddNewAccounts,
    cancelBatchAction: onCancelBatchAction,
    getRoles: onGetRoles,
    setRoles: onSetSelectedRoles,
    selectedAccountsCount: () => selectedIpBankAccounts.length,
    fillSelectInputs: setSelectValueOnCurrentPage,
  });

  const setCustomTableMethods = () => {
    Object.keys(METHODS).forEach((key) => {
      $userRolesTable[key] = METHODS[key];
    });
  };
  // #endregion Public Methods

  // #region Init
  const init = () => {
    initTable();
    initClickEventHandler();
    initChangeEventHandler();
    setCustomTableMethods();
  };
  // #endregion Init

  init();

  return $userRolesTable;
};

export default initUserRoleTable;
