function fieldByType($) {
  const nodes = $('.JS-field-by-type');

  if (!nodes.length) return;

  const changeLabel = (fieldName, selectedValue, fieldConfigs) => {
    $(`label[for=${fieldName}]`).text(fieldConfigs[selectedValue].label);
  };

  const changeLabelAndMask = (field, fieldName, selectedValue, fieldConfigs) => {
    const configs = fieldConfigs[selectedValue];

    changeLabel(fieldName, selectedValue, fieldConfigs);

    if (configs.mask) {
      field.mask(configs.mask, JSON.parse(configs.maskOptions));
    }
  };

  function updateFields(current, field, fieldName, fieldConfigs) {
    const selectedValue = current.val();

    if (selectedValue === '') {
      field.prop('readonly', 'readonly');
      field.val('');
      const regex = /\(%\)\*?|\((R\$|r\$)\)\*?/g;
      const labelText = $(`label[for=${fieldName}]`).text().replace(regex, '');
      $(`label[for=${fieldName}]`).text(labelText);
    } else {
      field.prop('readonly', '');
      changeLabelAndMask(field, fieldName, selectedValue, fieldConfigs);
    }
  }

  function composeField(fieldName, current) {
    return ($(`#${fieldName}`).length && $(`#${fieldName}`))
            || current
              .parent()
              .parent()
              .next()
              .find('input');
  }

  $('.JS-field-by-type').each((index, element) => {
    const current = $(element);
    const fieldName = current[0].dataset.field;
    const field = composeField(fieldName, current);
    const fieldConfigs = JSON.parse(current[0].dataset.fieldconfigs);

    if (current.val() === '') {
      field.prop('readonly', 'readonly');
    } else {
      changeLabel(fieldName, current.val(), fieldConfigs);
    }

    updateFields(current, field, fieldName, fieldConfigs);
  });

  $('.JS-field-by-type').change(function () {
    const current = $(this);
    const fieldName = current[0].dataset.field;
    const field = composeField(fieldName, current);
    const fieldConfigs = JSON.parse(current[0].dataset.fieldconfigs);
    updateFields(current, field, fieldName, fieldConfigs);
  });
}

export default fieldByType;

onLoad(($) => {
  fieldByType($);
});
