import { validateForm, disabledButtonForm } from '../helper/validate_form';

onLoad(($) => {
  const $formChargeData = $('.JS-form-charge-data').closest('form');
  if (!$formChargeData.length) return;

  const $inputDocumentNumber = $('#charge_document_number');
  const $inputValue = $('#charge_formatted_value');
  const $linkPayerEdit = $('#payerEditLink');
  const $buttonAddBeneficiary = $('#add-beneficiary');
  const $buttonContinue = $('.JS-button-charge-continue');
  const $listPayers = $('.JS-list-payer-info');
  const $wrapperApportionments = $('#apportionments');
  const $inputsRequired = () => $(`#charge_formatted_value, #charge_original_due_date,
    #charge_boleto_registration_method, .nested-fields select, .nested-fields input`);
  let documentNumberFieldFilled = false;
  let responsePayer = {};
  let isInputValueValid = false;
  const DECIMAL = 2;
  const MIN_VALUE_TO_SPLIT = 10;
  const TOOLTIP_SPLIT_TEXT = `O Split de Pagamentos só é permitido
    para cobranças com valor igual ou acima de R$ 10,00.`;

  function setDataPayer(payload) {
    const $payerValues = $listPayers.find('span');
    const payer = payload.data[0].attributes;
    const composePayerAddress = [
      payer.address.street || '',
      payer.address.number || '',
      payer.address.neighborhood || '',
      payer.address.city || '',
    ].filter(address => !!address).join(', ') + (payer.address.state ? `-${payer.address.state}` : '');

    $listPayers.removeClass('d-none');
    $payerValues.filter('#payerName').text(payer.name);
    $payerValues.filter('#payerDocumentNumber').text(payer['formatted-document-number']);
    $payerValues.filter('#payerEmail').text(payer.email);
    $payerValues.filter('#payerMessagingRule').text(payer['messaging-rule-name']);
    $payerValues.filter('#payerChargeGroup').text(payer['charge-group-name']);
    $payerValues.filter('#payerAddress').text(composePayerAddress);
    $payerValues.filter('#documentNumberField').val(payer.formatted_document_number);
    $linkPayerEdit.prop('href', `/payers/${payer.uuid}/edit?came_from_charge=true`);
    $linkPayerEdit.removeClass('d-none');
  }

  function handleValidateForm() {
    const $inputsRequiredFill = $inputsRequired().map(function () {
      return $(this).val().length;
    });
    if (!documentNumberFieldFilled) return;
    if (isInputValueValid) return;
    validateForm({
      $button: $buttonContinue,
      $form: $inputsRequiredFill,
    });
  }

  function handlePayerSuccess(data) {
    responsePayer = data;
    documentNumberFieldFilled = true;
    handleValidateForm();
    setDataPayer(data);
  }

  function handlePayerError() {
    $listPayers.addClass('d-none');
    $linkPayerEdit.addClass('d-none');
    $linkPayerEdit.prop('href', '#');
  }

  function handleSubmit(event, element) {
    event.preventDefault();
    const {
      name, phone, email, address,
    } = responsePayer.data[0].attributes;

    Object.entries(address).forEach((item) => {
      const [key, value] = item;
      const composeAddressName = key.replace('-', '_');
      const $inputHidden = $('<input>', {
        type: 'hidden',
        name: `charge[address_attributes][${composeAddressName}]`,
        value,
      });

      $(element).append($inputHidden);
    });

    $('#charge_name').val(name);
    $('#charge_phone').val(phone);
    $('#charge_email').val(email);
    element.submit();
  }

  function findPayer() {
    const documentNumberValue = $inputDocumentNumber.val();
    const isValidDocumentLength = document => document.length === 11 || document.length === 14;
    const isDocumentValid = isValidDocumentLength(documentNumberValue);
    if (!isDocumentValid) return;

    $.ajax({
      url: '/payers/search',
      type: 'get',
      dataType: 'json',
      data: { document_number: documentNumberValue },
      success: data => handlePayerSuccess(data),
      error: handlePayerError,
    });
  }

  function handleForm() {
    const documentNumber = $inputDocumentNumber.data('payer');
    if (documentNumber) $inputDocumentNumber.find('option').val(documentNumber);
    $listPayers.addClass('d-none');
    findPayer();
    $wrapperApportionments.find('select').each(function () {
      const $select = $(this);
      const value = $select.attr('data_id');
      $select.find('option').val(value);
    });
  }

  function handleButtonAddBeneficiary(value) {
    const isMinValueToSplit = Number(value) < MIN_VALUE_TO_SPLIT;
    $buttonAddBeneficiary[`${isMinValueToSplit ? 'add' : 'remove'}Class`]('btn-disabled');
    $buttonAddBeneficiary.parent().attr('data-original-title',
      `${isMinValueToSplit ? TOOLTIP_SPLIT_TEXT : ''}`);

    if (isMinValueToSplit && $('.nested-fields').length) {
      isInputValueValid = true;
      disabledButtonForm($buttonContinue);
      return;
    }
    isInputValueValid = false;
  }

  function handleInputValue($input) {
    const value = $input.val();
    const sanitizeValue = value.replace(',', '');
    if (sanitizeValue.length <= DECIMAL) {
      handleButtonAddBeneficiary(sanitizeValue);
      return;
    }
    const integerValue = sanitizeValue.slice(0, -DECIMAL);
    const decimalValue = sanitizeValue.slice(-DECIMAL);
    const composeValue = Number(`${integerValue}.${decimalValue}`);
    handleButtonAddBeneficiary(composeValue);
  }

  handleForm();

  $inputDocumentNumber.on('change', findPayer);

  $inputsRequired().on('change input', handleValidateForm);

  $formChargeData.on('submit', function (event) {
    handleSubmit(event, this);
  });

  if (!$wrapperApportionments.length) return;

  $wrapperApportionments
    .on('cocoon:after-insert', () => {
      $('.input--percentage').mask('##99,99', { reverse: true, placeholder: '0,00%', watchDataMask: true });
      handleValidateForm();
      $inputsRequired().on('change input', handleValidateForm);
    })
    .on('cocoon:after-remove', () => {
      handleValidateForm();
    });

  $inputValue.on('input', function () {
    handleInputValue($(this));
  });
});
