const DateHelper = () => {
  const toArray = (dateString, convertMonth = true) => {
    const char = dateString.includes('/') ? '/' : '-';
    if (!convertMonth) {
      return dateString.split(char);
    }
    return dateString.split(char).map((item, index) => Number(index !== 1 ? item : item - 1));
  };

  const validate = (dateString) => {
    const [day, month, year] = toArray(dateString);

    const daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    if ((!(year % 4) && year % 100) || !(year % 400)) {
      daysInMonth[1] = 29;
    }
    return !(/\D/.test(String(day))) && day > 0 && day <= daysInMonth[month];
  };

  const formatted = (dateString) => {
    const dateArray = toArray(dateString, false);
    return (new Date(dateArray)).toLocaleDateString('pt-BR');
  };

  return {
    toArray,
    validate,
    formatted,
  };
};

export default DateHelper;
