import CurrencyHelper from '../helper/currencyHelper';

onLoad(($) => {
  const $chargeGroupButtonModal = $('.JS-charge-group-button-modal');
  if (!$chargeGroupButtonModal.length) return;

  const currencyHelper = new CurrencyHelper();
  function composePercentageFormat(value) {
    return `${String(value).replace('.', ',')}%`;
  }

  const templateTable = discount => `
    <table class="table-alt mt-4">
      <tbody>
        <tr>
          <th> Modelo de desconto </th>
          <td>${discount.translated_discount_type ? discount.translated_discount_type : ''}</td>
        </tr>
        <tr>
          <th> Desconto (%) </th>
          <td>${discount.value ? `${composePercentageFormat(discount.value)}` : ''}</td>
        </tr>
        <tr>
          <th> Limite para aplicar desconto (dias) </th>
          <td>
            ${discount.days_before_due_date ? (`${discount.days_before_due_date} 
              dia${Number(discount.days_before_due_date) > 1 ? 's' : ''}
              antes do vencimento`) : ''}
          </td>
        </tr>
      </tbody>
    </table>
  `;
  const templateTag = "<div class='tag dark sm'>Grupo padrão atual</div>";

  function handleName($element, value) {
    $element.text(value);
  }

  function handleDaysWriteOffAfterDueDate($element, value) {
    $element.text(value);
  }

  function handleMonthlyInterest($element, value) {
    if (!value) {
      $element.text('');
      return;
    }
    const $th = $element.closest('tr').find('th');
    const suffixText = (suffix = '') => `${$th.text().slice(0, 13)} ${suffix}`;
    if (value.interest_type_cd === 'percentage') {
      $element.text(composePercentageFormat(value.monthly_interest));
      $th.text(suffixText('(%)'));
      return;
    }
    $element.text(`${currencyHelper.format(value.monthly_interest)}`);
    $th.text(suffixText());
  }

  function handleApplicablefine($element, value) {
    $element.text(`${value ? composePercentageFormat(value) : ''}`);
  }

  function handleIsDefault($element, value) {
    $element.html(`${value ? templateTag : ''}`);
  }

  function handleDiscounts($element, value) {
    $element.html('');
    if (!value.length) return;
    value.forEach(discount => $element.append(templateTable(discount)));
  }

  function handleDescription($element, value) {
    $element.text(value);
  }

  function handleObservation($element, value) {
    $element.text(value);
  }

  $chargeGroupButtonModal.on('click', function () {
    const data = {
      name: ($element, value) => handleName($element, value),
      'days-write-off-after-due-date': ($element, value) => handleDaysWriteOffAfterDueDate($element, value),
      'monthly-interest': ($element, value) => handleMonthlyInterest($element, value),
      'applicable-fine': ($element, value) => handleApplicablefine($element, value),
      'is-default': ($element, value) => handleIsDefault($element, value),
      discounts: ($element, value) => handleDiscounts($element, value),
      description: ($element, value) => handleDescription($element, value),
      observation: ($element, value) => handleObservation($element, value),
    };
    const attributes = Object.keys(data);
    attributes.forEach((attribute) => {
      data[attribute]($(`.JS-charge-group-modal-${attribute}`), $(this).data(attribute));
    });
  });
});
