onLoad(($) => {
  const $balanceCardCollapse = $('.balance-card.collapse');
  if (!$balanceCardCollapse) return;

  const $collapseBtn = $balanceCardCollapse.find('.collapse-btn');

  $collapseBtn.on('click', (e) => {
    const $this = $(e.currentTarget);
    const $balanceCard = $this.closest('.balance-card');
    const $value = $balanceCard.find('.value');

    const isExpanded = $this.attr('aria-expanded') === 'true';

    $balanceCard.css('box-shadow', isExpanded ? 'none' : '0px 4px 8px 0px rgba(0, 0, 0, 0.08)');
    $value.css('font-size', isExpanded ? '28px' : '30px');
    $value.css('font-weight', isExpanded ? '500' : '600');
  });
});
