onLoad(($) => {
  const $textareaAlt = $('.JS-textarea-alt');
  if (!$textareaAlt.length) return;

  const $textarea = $textareaAlt.find('textarea');
  const textAreaValue = $textarea.val();
  const $labelCharacters = $('.JS-textarea-alt-label-characters');
  const maxCharacters = $textarea.attr('maxlength');

  function setCharacters(length) {
    $labelCharacters.text(`${length} / ${maxCharacters}`);
  }

  if (textAreaValue) setCharacters(textAreaValue.length);

  $textarea.on('input', (event) => {
    const characters = event.target.value.length;
    if (characters > Number(maxCharacters)) return;
    setCharacters(event.target.value.length);
  });
});
