onLoad(($) => {
  const $formSanitize = $('.JS-sanitize-form');
  if (!$formSanitize.length) return;

  const $inputSanitize = () => $('.JS-sanitize-input');
  const sanitize = (_, value) => value.replace('.', '').replace(',', '.');

  function handleSubmit(event, $element) {
    event.preventDefault();

    $inputSanitize().each(function () {
      $(this).val(sanitize);
    });

    $element.submit();
  }

  $formSanitize.on('submit', function (event) {
    handleSubmit(event, this);
  });
});
