export const PAGES = Object.freeze({
  preInvitation: 'preInvitation',
  invitation: 'invitation',
  addAccount: 'addAccount',
});

export const CONTEXT = Object.freeze({
  invite: 'invite',
  edit: 'edit',
});

// #region Private Function
const getInvitationPage = (page) => {
  switch (page) {
    case 'preInvitation':
      return '.JS-pre-invitation';
    case 'invitation':
      return '.JS-invitation';
    case 'addAccount':
      return '.JS-user-add-account';
    default:
      return null;
  }
};

const getEditPage = (page) => {
  switch (page) {
    case 'invitation':
      return '.JS-invitation';
    case 'addAccount':
      return '.JS-user-add-account';
    default:
      return null;
  }
};

const toggleVisibility = ({ $parent, page }) => {
  if (!$parent.length) return;

  const $page = $parent.find(page);

  if (!$page.length) return;

  $page.removeClass('d-none');

  $parent.children().not(page).addClass('d-none');

  $('html').scrollTop(0);
};
// #region Private Function

export const UserManagementNavigator = class Navigator {
  constructor({
    context,
    parentContainerSelector,
  }) {
    this.context = context;
    this.pages = PAGES;
    this.parentContainerSelector = parentContainerSelector;
  }

  navigate(page) {
    const nextPage = this.context === CONTEXT.invite
      ? getInvitationPage(page)
      : getEditPage(page);

    toggleVisibility({
      $parent: $(this.parentContainerSelector),
      page: nextPage,
    });
  }
};
