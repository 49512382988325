onLoad(($) => {
  if (!$('.ds-qr-code-pix-received-index').length) return;

  const $sidemenuItem = $('.sidebar-menu').find('a[href="/transfer_requests/pix_history"]');
  const $listGrgoupItem = $('.sidebar-menu').find('a[href="#pix"]');
  const $submenuArrow = $listGrgoupItem.find('.submenu-arrow');
  const $buttonDownloadPdfBatch = $('#JS-button-pix-download-pdf-batch');
  const $checkboxes = $('input[type="checkbox"]').filter(function () {
    const id = $(this).attr('id');
    return id.includes('resource') || id.includes('select_all');
  });
  const $checkboxesChecked = () => $checkboxes.filter(function () {
    return $(this).is(':checked');
  });

  $sidemenuItem.addClass('active');
  $sidemenuItem.closest('.collapse').addClass('show').attr('aria-expanded', 'true');
  $listGrgoupItem.addClass('active').attr('aria-expanded', 'true');
  $submenuArrow.addClass('turn');

  function handleCheckbox() {
    $buttonDownloadPdfBatch[`${$checkboxesChecked().length ? 'remove' : 'add'}Class`]('d-none');
  }

  function handleButtonDownloadPdfBatch() {
    const url = new URL(`${window.location.pathname}/batch_donwload_qrcode_pix`, window.location.origin);
    $checkboxesChecked().each(function () {
      url.searchParams.append('ids[]', $(this).val());
    });
    window.location.href = url;
  }

  async function eventPropagation(event) {
    return new Promise((resolve) => {
      resolve(event.bubbles);
    });
  }

  $checkboxes.on('change', async (event) => {
    const isEventPropagation = await eventPropagation(event);
    if (isEventPropagation) handleCheckbox();
  });

  $buttonDownloadPdfBatch.on('click', handleButtonDownloadPdfBatch);
});
