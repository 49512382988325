// #region Private Functions
const getFetchProfilesOptions = () => ({
  url: $('#fetch_profiles_url').val(),
  type: 'get',
  dataType: 'json',
});

const getFetchRolesOptions = () => ({
  url: 'fetch_roles',
  type: 'get',
  dataType: 'json',
});

const parseIpBankAccounts = accounts => accounts.map((account) => {
  const {
    id,
    name,
    agency,
    client_id: clientId,
    account_number: accountNumber,
    document_number: documentNumber,
    kind,
  } = account;

  return {
    ip_bank_account_id: id,
    name,
    agency,
    client_id: clientId,
    account_number: accountNumber,
    document_number: documentNumber,
    kind,
  };
});

const parseUserProfiles = ({ occupations, roles_with_legacy: rolesWithLegacy }) => ({
  occupations,
  roles: rolesWithLegacy.filter(r => !r.legacy),
  legacyRoles: rolesWithLegacy.filter(r => r.legacy),
});

const parseRoles = ({ roles }) => roles.map((role) => {
  const {
    document_number: documentNumber,
    id,
    ip_bank_account_id: ipBankAccountId,
    kind: { key, legacy },
    name,
  } = role;

  return {
    account_number: name,
    document_number: documentNumber,
    id,
    ip_bank_account_id: ipBankAccountId,
    isKindLegacy: legacy,
    kind_cd: key,
  };
});
// #endregion Private Functions

const buttonTexts = {};
export const toggleBtnLoadIndicator = ($button, key, isLoading = false) => {
  const html = "<span class='spinner-border spinner-border-sm text-light' role='status'></span> Aguarde...";

  if (!buttonTexts[key]) buttonTexts[key] = $button.html();

  if (isLoading) {
    $button.html(html);
    $button.prop('disabled', true);
  } else {
    $button.html(buttonTexts[key]);
    $button.prop('disabled', false);
  }
};

export const listIpBankAccounts = ({
  $,
  params,
  onSuccess = () => { },
  onError = () => { },
}) => {
  $.ajax({
    url: $('#fetch_accounts_url').val(),
    type: 'get',
    dataType: 'json',
    data: params,
    success: response => onSuccess(parseIpBankAccounts(response.accounts)),
    error: onError,
  });
};

export const validateUserForm = ({
  $,
  params,
  onSuccess = () => { },
  onError = () => { },
}) => {
  $.ajax({
    url: 'pre_validation',
    type: 'post',
    dataType: 'json',
    data: params,
    success: response => onSuccess(response.responseJSON),
    error: (response) => {
      if (response.status === 422) {
        onSuccess(response.responseJSON);
      } else {
        onError(response);
      }
    },
  });
};

export const fetchUserProfiles = ({
  $,
  onSuccess = () => { },
  onError = () => { },
}) => {
  const { url, type, dataType } = getFetchProfilesOptions();

  $.ajax({
    url,
    type,
    dataType,
    success: response => onSuccess(parseUserProfiles(response)),
    error: onError,
  });
};

export const fetchRoles = ({
  $,
  onSuccess = () => { },
  onError = () => { },
}) => {
  const { url, type, dataType } = getFetchRolesOptions();

  $.ajax({
    url,
    type,
    dataType,
    success: response => onSuccess(parseRoles(response)),
    error: onError,
  });
};

export const fetchUserProfilesAndRoles = ({
  $,
  onSuccess = () => { },
  onError = () => { },
}) => {
  $.when(
    $.ajax(getFetchProfilesOptions()),
    $.ajax(getFetchRolesOptions()),
  ).then((profiles, roles) => {
    onSuccess({
      profiles: parseUserProfiles(profiles[0]),
      roles: parseRoles(roles[0]),
    });
  }).fail(onError);
};
