const setOwnerOptions = ($select, ipBankAccounts = []) => {
  const names = new Map(ipBankAccounts.map(item => [item.client_id, item]));
  if (names.size === 0) return;

  names.values().forEach((account) => {
    $select.append($('<option>', {
      value: account.client_id,
      text: account.name,
    }));
  });
};

export default setOwnerOptions;
