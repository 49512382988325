const onChangeVisibility = function (element, callback) {
  const options = {
    root: document.documentElement,
  };

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      callback(entry.intersectionRatio > 0);
    });
  }, options);

  observer.observe(element);
};

export default onChangeVisibility;
