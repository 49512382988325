onLoad(($) => {
  const $callout = $('.callout');

  if (!$callout.length) return;

  const $closeBtn = $callout.find('.close-btn');

  $closeBtn.on('click', (e) => {
    $(e.currentTarget).closest('.callout').addClass('d-none');
  });
});
