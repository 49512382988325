import GeneralShimmer from './helper/generalShimmer';
import CurrencyHelper from './helper/currencyHelper';

onLoad(($) => {
  if (!$('.ds-grafeno-statement-entries').length) return;

  const currencyHelper = new CurrencyHelper();

  const $entryAtBetweenSelect = $('.JS-entry-at-between');
  const $statementEntriesDateForm = $('.JS-statement-entries-date');
  const $initialDate = $('.JS-initial-date');
  const $finalDate = $('.JS-final-date');
  const $collapseBtn = $('.collapse-btn');
  const entryAtBetweenValue = $entryAtBetweenSelect.last().children('option:selected').val();
  const statementEntriesValue = {
    balance: null,
    unavailable_balance: null,
    available_balance_details: null,
    unavailable_balance_details: null,
  };

  function handleError() {
    Swal.fire({
      title: 'Erro',
      text: 'Não foi possível consultar este valor.',
      icon: 'error',
    });
  }

  function verifyEntryAtBetweenValue(optionValue) {
    if (optionValue === 'custom_range') {
      $statementEntriesDateForm.removeClass('d-none').addClass('d-flex');
    } else {
      $initialDate.val('').change();
      $finalDate.val('').change();
      $statementEntriesDateForm.addClass('d-none').removeClass('d-flex');
    }
  }

  function verifyDateRequired(value, input) {
    if (value) {
      input.prop('required', true);
    } else {
      input.prop('required', false);
    }
  }

  function updateBalanceCard(data, type) {
    statementEntriesValue[type] = data[type];
    const html = type === 'balance' ? '.balance-total-value' : '.balance-unavailable-value';
    $(html).html(`<div>${currencyHelper.format(statementEntriesValue[type])}</div>`);
  }

  function updateCollapseCard(data, type) {
    statementEntriesValue[type] = data;
    const $valueInfoContainer = $(`#${type}_collapse`).find('.value-info-container');
    $.each(data.data, (index, element) => {
      if (element.value === null) return;
      const title = `<div class="info-title">${element.title}</div>`;
      const value = `<div class="info-value">${element.value}</div>`;
      $valueInfoContainer.append(title);
      $valueInfoContainer.append(value);
    });
  }

  function getStatementEntriesValue(isCollapse, type) {
    const path = isCollapse ? `/ip_bank_accounts/fetch_${type}` : `/grafeno_statement_entries/${type}`;
    const updateData = data => (isCollapse ? updateCollapseCard(data, type) : updateBalanceCard(data, type));

    if (statementEntriesValue[type] === null) {
      $.ajax({
        url: path,
        type: 'get',
        dataType: 'json',
        data: { },
      }).done(data => updateData(data))
        .fail(() => handleError());
    } else {
      updateData(statementEntriesValue);
    }
  }

  $('.sidebar-menu').find('a[href="/grafeno_statement_entries"').addClass('active');

  $('.application-content').addClass('bg-white');

  $('.JS-balance-total-value')
    .each((_, el) => new GeneralShimmer($(el), '.toggle-balance-total-icon.JS-toggle-balance'));

  $('.JS-unavailable-total-value')
    .each((_, el) => new GeneralShimmer($(el), '.toggle-balance-total-icon.JS-toggle-unavailable'));

  $('.toggle-balance-total-icon').on('click', (e) => {
    const type = $(e.currentTarget).hasClass('JS-toggle-balance') ? 'balance' : 'unavailable_balance';
    getStatementEntriesValue(false, type);
  });

  $collapseBtn.on('click', (e) => {
    const type = $(e.currentTarget).hasClass('unavaliable-balance') ? 'unavailable_balance' : 'available_balance';
    getStatementEntriesValue(true, `${type}_details`);
  });

  $entryAtBetweenSelect.on('change', () => {
    const optionValue = $entryAtBetweenSelect.last().children('option:selected').val();
    verifyEntryAtBetweenValue(optionValue);
  });

  $initialDate.on('change', () => {
    const intialValue = $initialDate.last().val();
    verifyDateRequired(intialValue, $finalDate);
  });

  $finalDate.on('change', () => {
    const finalValue = $finalDate.last().val();
    verifyDateRequired(finalValue, $initialDate);
  });

  verifyEntryAtBetweenValue(entryAtBetweenValue);
});
