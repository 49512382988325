onLoad(($) => {
  if (!$('.tab-container')) return;

  const locationPath = window.location.pathname;
  const locationSearch = window.location.search;
  const isSearchPage = locationSearch.length > 0;
  const tabItem = !isSearchPage ? `a[href^='${locationPath}']` : `a[href='${locationPath}']`;
  $('.tab-container').find(tabItem).first().addClass('active');

  const tabs = $('.tab a');

  tabs.on('click', function () {
    tabs.closest('.tab').removeClass('active');
    $(this).closest('.tab').addClass('active');
  });
});
