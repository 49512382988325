// #region Public Functions
const paginate = (pageOptions) => {
  const { list, pageSize, page } = pageOptions;
  const offset = 0;
  const limit = pageSize;

  const startIndex = (page - 1) * pageSize + offset;
  const endIndex = startIndex + pageSize;
  const endIndexWithLimit = limit
    ? Math.min(endIndex, startIndex + limit)
    : endIndex;

  return list.slice(startIndex, endIndexWithLimit);
};

export const renderPagesList = (current, max) => {
  if (!current || !max) return [1];
  const items = [1];

  if (current === 1 && max === 1) {
    return items;
  }
  if (current > 4) items.push('…');

  const visiblePagesAmount = 2;
  const previousPagesLimit = current - visiblePagesAmount;
  const nextPagesLimit = current + visiblePagesAmount;

  for (let i = previousPagesLimit > 2 ? previousPagesLimit : 2; i <= Math.min(max, nextPagesLimit); i += 1) {
    items.push(i);
  }

  if (nextPagesLimit + 1 < max) items.push('…');
  if (nextPagesLimit < max) items.push(max);

  return items;
};
// #endregion Public Functions

export default paginate;
