// #region Private Functions
const mapAndJoin = (items = [], callback = () => { }) => items.map(i => callback(i)).join('');

const buildColumnHeader = column => `<th>${column}</th>`;

const renderColumnValue = (valueKey, row) => {
  if (valueKey.render) {
    return valueKey.render(row);
  }

  return row[valueKey.key];
};

const renderCell = (valueKey, row, idKey) => {
  if (valueKey.key === 'checkbox') {
    return `<input type="checkbox" data-row-id="${row[idKey]}" id="child-checkbox-${row[idKey]}" class="checkbox" />`;
  }

  return renderColumnValue(valueKey, row);
};

const buildTableRowCell = (valueKey, row, idKey) => `<td>${renderCell(valueKey, row, idKey)}</td>`;

const buildTableRowColumns = (row, keys, idKey, showCheckbox) => {
  const rowKeys = showCheckbox ? [{ key: 'checkbox' }, ...keys] : keys;

  return `<tr>${mapAndJoin(rowKeys, valueKey => buildTableRowCell(valueKey, row, idKey))}</tr>`;
};

const renderCheckbox = (showCheckbox) => {
  if (showCheckbox) {
    return '<td><input type="checkbox" id="parent-checkbox" class="checkbox" /></td>';
  }

  return '';
};

const renderPageIndicator = (page, currentPage) => {
  const isPageActive = currentPage === page;
  const activeClass = isPageActive ? 'active' : '';

  return `<li class="page-item ${activeClass}">
            <a class="page-link">${page}</a>
          </li>`;
};

const renderPages = (pages, currentPage) => mapAndJoin(
  pages,
  page => renderPageIndicator(page, currentPage),
);
// #endregion Private Functions

// #region Public Functions
export const createTable = () => `<div class="table-responsive paginated-table">
                                    <div class="select-toggle"></div>
                                    <table class="table"></table>
                                  </div>
`;

export const buildTableHeader = (columns, showCheckbox) => `
    <thead>
      <tr>
        ${renderCheckbox(showCheckbox)}
        ${mapAndJoin(columns, buildColumnHeader)}
      </tr>
    </thead>
  `;

export const buildTableBody = ({
  rows, modelKeys, idKey, showCheckbox,
}) => {
  const tbodyId = 'resources-tbody';
  const keys = modelKeys.filter(key => key.key !== idKey);

  return `<tbody id="${tbodyId}">
            ${mapAndJoin(rows, row => buildTableRowColumns(row, keys, idKey, showCheckbox))}
          </tbody>`;
};

export const buildPages = (pages = [], currentPage) => {
  const disablePrevButton = currentPage === pages[0] ? 'btn-disabled' : '';
  const disableNextButton = currentPage === pages[pages.length - 1] ? 'btn-disabled' : '';

  return `
    <ul class="table-pagination">
      <button
        type="button"
        class="pagination-prev-btn ${disablePrevButton}"
        id="prev-page">
        <i class="fas fa-chevron-left"></i>
      </button>
      <div class="pagination-list">
        ${renderPages(pages, currentPage)}
      </div>
      <button
        type="button"
        class="pagination-next-btn ${disableNextButton}"
        id="next-page">
        <i class="fas fa-chevron-right"></i>
      </button>
    </ul>
  `;
};

export const buildTableFooter = ({ colspan = 0, pages = [], currentPage }) => `
  <tfoot class="pagination-footer">
    <td colspan="${colspan}">
      <div class="d-flex justify-content-end">
        <div class="pagination-container ml-3">
          ${buildPages(pages, currentPage)}
        </div>
      <div>
    </td>
  </tfoot>
`;

export const buildSelectAllRowsToggle = () => `
    <div class="select-all-rows-toggle d-none justify-content-center">
      <p class="mb-0">
        <div class="toggle-text">
          <span class="default-text">Todas as <span class="current-page-amount">
          </span> contas dessa página foram selecionadas.</span>
            <span class="many-selected-text d-none"><span class="current-page-amount"></span> contas foram selecionadas.
          </span>
          <span class="toggle">Selecione todas as <span class="total-amount"></span> contas disponíveis</span>
        </div>
        <div class="undo-selection-text d-none">
          Todas as <span class="current-selected-amount"></span> contas disponíveis foram selecionadas.
          <span class="undo-selection">Desfazer seleção</span>
        </div>
      </p>
    </div>
  `;
// #endregion Public Functions
