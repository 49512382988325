/* eslint-disable no-unused-vars, no-undef */
import validateInputDate from './validate_input_date';
import DateHelper from './helper/dateHelper';

const dateHelper = new DateHelper();

function datepicker($) {
  const DatePicker = ($element) => {
    const translations = {
      monthsFull: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro',
        'Novembro', 'Dezembro'],
      monthsShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Maio', 'Jun', 'Jul', 'Agos', 'Set', 'Out', 'Nov', 'Dez'],
      weekdaysFull: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
      weekdaysShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
    };

    const buttons = {
      today: 'Hoje',
      clear: 'Limpar',
      close: 'Fechar',
    };

    const labels = {
      labelMonthNext: 'Próximo mês',
      labelMonthPrev: 'Mês anterior',
      labelMonthSelect: 'Selecione um mês',
      labelYearSelect: 'Selecione um ano',
    };

    const formats = {
      format: 'dd/mm/yyyy',
      editable: true,
    };

    const dropdown = {
      selectYears: 100,
      selectMonths: true,
    };

    const removeCommasDataAttr = (attr) => {
      const comma = '"';
      const initialChar = $element.data(attr)[0];
      const lastChar = $element.data(attr).slice(-1);

      if (initialChar === comma && lastChar === comma) {
        return $element.data(attr).substring(1, $element.data(attr).length - 1);
      }
      return $element.data(attr);
    };

    const getDateLimitMax = () => {
      if ($element.data('max')) {
        return new Date(removeCommasDataAttr('max').split('-'));
      }
      const currentDate = new Date();
      return new Date(currentDate.getFullYear() + 5, currentDate.getMonth(), currentDate.getDate());
    };

    const dateLimit = {
      min: new Date(1900, 1, 1),
      max: getDateLimitMax(),
    };

    const handleKeyDown = (picker) => {
      $('body').on('keydown', () => {
        picker.pickadate('close', true);
      });
    };

    const handleShowDatePicker = () => {
      const picker = $element.pickadate();

      handleKeyDown(picker);

      const min = $element.data('min') ? dateHelper.toArray(removeCommasDataAttr('min')) : '';
      const max = $element.data('max') ? dateHelper.toArray(removeCommasDataAttr('max')) : '';

      if (min) picker.pickadate('set', { min });
      if (max) picker.pickadate('set', { max });
    };

    const onClose = () => {
      $('body').off('keydown');
    };

    return Object.assign({}, { onOpen: handleShowDatePicker, onClose },
      translations,
      buttons,
      labels,
      formats,
      dropdown,
      dateLimit);
  };

  const $datepickerList = $('.datepicker');

  $datepickerList.each(function () {
    const $this = $(this);

    const datePicker = new DatePicker($this);
    $this.pickadate(datePicker);

    $this.on('change', () => {
      if ($this.val()) validateInputDate($this, datePicker.min, datePicker.max);
    });

    $this.mask('99/99/9999');
  });
}

export default datepicker;

onLoad(($) => {
  datepicker($);
});

/* eslint-enable no-unused-vars, no-undef */
