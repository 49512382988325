/* eslint no-console:0 */
/* eslint-disable no-unused-vars, no-undef, import/no-unresolved, func-names, prefer-arrow-callback */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images import.context('../images', true)
// const imagePath = (name) => images(name, true)
import Turbolinks from 'turbolinks';
import Rails from 'rails-ujs';
import 'jquery-ui-css/core.css';
import 'jquery-ui-css/autocomplete.css';
import autocomplete from 'jquery-ui/autocomplete';
import 'bootstrap';
import swal from 'sweetalert2';
import '../javascripts/confirm';
import 'pickadate/lib/picker';
import 'pickadate/lib/picker.date';
import 'select2/dist/js/select2.full.min';
import 'select2/dist/js/i18n/pt-BR';
import 'cocoon-js';

import '../javascripts/utils';

import '../javascripts/api_token';
import '../javascripts/api_token_extra_fields';
import '../javascripts/ask_when_other';
import '../javascripts/automatic_rule';
import '../javascripts/automation_rules_select';
import '../javascripts/beneficiary_info';
import '../javascripts/billing_expense_management';
import '../javascripts/callout_news_short';
import '../javascripts/callout';
import '../javascripts/card';
import '../javascripts/ccb/company_signers';
import '../javascripts/ccb/company_data';
import '../javascripts/ccb/new';
import '../javascripts/ccb/operation_values';
import '../javascripts/ccb/warranty';
import '../javascripts/change_ip';
import '../javascripts/charge_concession';
import '../javascripts/charge_form';
import '../javascripts/charge_simulated_values';
import '../javascripts/charges/cancel_protest_modal';
import '../javascripts/charges/form_charge_data';
import '../javascripts/charges/form_rates_and_discounts';
import '../javascripts/charges/resend_protest_modal';
import '../javascripts/charges/toggle_totals';
import '../javascripts/charges/tooltip';
import '../javascripts/charge_imports/charge_imports_new';
import '../javascripts/charge_imports/create';
import '../javascripts/charges/form/char_counter';
import '../javascripts/charges/form/charge_group_table';
import '../javascripts/charges/form/messaging_rule_table';
import '../javascripts/charge_groups/index';
import '../javascripts/charge_groups/form_discounts';
import '../javascripts/client/requested_registration/originator';
import '../javascripts/client/requested_registration/timeline';
import '../javascripts/client/external_onboarding';
import '../javascripts/clients/users/add_accounts_to_invitation';
import '../javascripts/clients/users/handle_batch_actions';
import '../javascripts/clients/users/user_management/async_actions';
import '../javascripts/clients/users/user_management/edit_user';
import '../javascripts/clients/users/user_management/forms/add_account_form';
import '../javascripts/clients/users/user_management/forms/automatic_rule_form';
import '../javascripts/clients/users/user_management/forms/user_form';
import '../javascripts/clients/users/user_management/helpers/form_filler_helper';
import '../javascripts/clients/users/user_management/helpers/ip_bank_accounts_filter_helper';
import '../javascripts/clients/users/user_management/helpers/navigation_helper';
import '../javascripts/clients/users/user_management/helpers/validation_helper';
import '../javascripts/clients/users/user_management/helpers/visibility_helper';
import '../javascripts/clients/users/user_management/invite_user';
import '../javascripts/clients/users/user_management/tables/add_account_table';
import '../javascripts/clients/users/user_management/tables/invitation_role_table';
import '../javascripts/clients/users/user_management/tables/pre_invitation_accounts_table';
import '../javascripts/clients/users/redirect_on_reset';
import '../javascripts/clients/users/select_all';
import '../javascripts/clients/users/tooltip';
import '../javascripts/clients/users/validate_onboarding_phone';
import '../javascripts/cnab_imports/import_file';
import '../javascripts/helper/sanitize_form';
import '../javascripts/users/forgot_password';
import '../javascripts/users/validate_password';
import '../javascripts/cocoon';
import '../javascripts/commercial_note/simulation';
import '../javascripts/conciliation/index';
import '../javascripts/conciliation/item';
import '../javascripts/conditional_hide';
import '../javascripts/copy_to_clipboard';
import '../javascripts/create_internal_beneficiary';
import '../javascripts/datepicker';
import '../javascripts/dashboardCards';
import '../javascripts/design_system/index';
import '../javascripts/documentoscopies/auto_refresh';
import '../javascripts/enabled_switch';
import '../javascripts/field_by_type';
import '../javascripts/fill_address_with_cep';
import '../javascripts/filter';
import '../javascripts/froala-editor';
import '../javascripts/grafeno_statement_entries';
import '../javascripts/highlight_filters';
import '../javascripts/horizontal_timeline';
import '../javascripts/input_disable_handler';
import '../javascripts/input_money_alt';
import '../javascripts/input_or_specified_value';
import '../javascripts/ip_bank_account_info';
import '../javascripts/ip_bank_account/beneficiaries/new_form';
import '../javascripts/ip_bank_account/beneficiaries/new_pix';
import '../javascripts/ip_bank_account/beneficiaries/release';
import '../javascripts/ip_bank_account/receipts/batch_download';
import '../javascripts/ip_bank_account/report_incomes';
import '../javascripts/masks';
import '../javascripts/messaging_rules';
import '../javascripts/multiple_autocomplete';
import '../javascripts/onboarding/grantor/main';
import '../javascripts/payment/barcode';
import '../javascripts/payment/index_multiselect';
import '../javascripts/payment/index';
import '../javascripts/payment/pending_index';
import '../javascripts/pix_keys_index';
import '../javascripts/pix_account';
import '../javascripts/pix_create_key';
import '../javascripts/pix/pix_qr_code_payment';
import '../javascripts/pix/pix_qr_code';
import '../javascripts/pix/pix_transfer';
import '../javascripts/protest/validate';
import '../javascripts/protest/pending_approval_multiselect';
import '../javascripts/protest/approve';
import '../javascripts/protest/agreement/approve';
import '../javascripts/qr_code_pix_receiveds/index';
import '../javascripts/qr-code-pix-received-new';
import '../javascripts/select_all';
import '../javascripts/select_autocomplete';
import '../javascripts/select_batch';
import '../javascripts/select_when_transfer';
import '../javascripts/sidebar';
import '../javascripts/submit_and_redirect_btn';
import '../javascripts/swal_alert';
import '../javascripts/swal_grafeno_statement_entries';
import '../javascripts/swal_details';
import '../javascripts/swal_invoice';
import '../javascripts/swal_receipt';
import '../javascripts/swal_receipt_send_pdf';
import '../javascripts/term_acceptance';
import '../javascripts/terms/notifications';
import '../javascripts/terms/opening_account/ip_bank_accounts';
import '../javascripts/toggle_checkbox';
import '../javascripts/transfer_request';
import '../javascripts/transfer_request_imports/confirm_to_skip_ted';
import '../javascripts/transfer_requests/approve_all';
import '../javascripts/transfer_requests/approve';
import '../javascripts/transfer_requests/imports';
import '../javascripts/transfer_requests/index_multiselect';
import '../javascripts/transfer_requests/new_requests';
import '../javascripts/transfer_requests/pending_index_multiselect';
import '../javascripts/user_roles/index';
import '../javascripts/users/alerts/container';
import '../javascripts/users/pin';
import '../javascripts/users/expired_password';
import '../javascripts/users/grafeno_authenticator';
import '../javascripts/users/sign_in';
import '../javascripts/toggle_password';
import '../javascripts/users/two_factor_authentication';
import '../javascripts/values_by_select';
import '../javascripts/verify_status';
import '../javascripts/payment/confirm_barcode';
import '../javascripts/reload_page';
import 'jquery-mask-plugin/dist/jquery.mask.min';
import '../javascripts/hubspot_chat';

import '@fortawesome/fontawesome-free/js/fontawesome.min';
import '@fortawesome/fontawesome-free/js/solid.min';
import '@fortawesome/fontawesome-free/js/regular.min';
import '@fortawesome/fontawesome-free/js/brands.min';

Turbolinks.start();
window.Turbolinks = Turbolinks;
window.$ = $;
window.Swal = swal;

Rails.start();

window.Rails = Rails;

onLoad(function ($) {
  $('.tooltip').remove();
  $('.JS-default-modal-show').modal('show');
  $('[data-toggle="tooltip"]').tooltip({ boundary: 'window', html: true });
  $('[data-remote="true"]').on('click', e => !(e.metaKey || e.ctrlKey));
});
onLoad(function ($) { $('[data-toggle="popover"]').popover(); });

/* eslint-disable */
/* eslint-disable no-unused-vars, no-undef */
FontAwesome.config.mutateApproach = 'sync';
