import CurrencyHelper from '../helper/currencyHelper';

onLoad(($) => {
  if (!$('.ds-barcode-payments-pending-index').length) return;

  const $checkboxes = $('input[type="checkbox"]');
  const $buttonApprove = () => $('[data-action="approve"]');
  const $buttonReject = () => $('[data-action="reject"]');
  const NUMERICAL_SYSTEM = 100;
  const INITIAL_VALUE = 0;
  let total = INITIAL_VALUE;
  let quantity = INITIAL_VALUE;
  let recordsTotal = INITIAL_VALUE;

  const convertToNumber = value => Number(value) * NUMERICAL_SYSTEM;
  const convertToString = value => String(value / NUMERICAL_SYSTEM);

  function handleModalContent($element) {
    const isCnabImports = $element.data('type') === 'cnab_imports';
    const qtd = $element.data('all') ? quantity : '1';
    const records = $element.data('recordTotal') || recordsTotal;

    if (isCnabImports) {
      return `
       <strong>${qtd} arquivo${qtd > 1 ? 's' : ''} de remessa</strong>
       contendo ${records} solicitaç${records > 1 ? 'ões' : 'ão'} de pagamentos,
      `;
    }
    return `<strong>${qtd} solicitaç${qtd > 1 ? 'ões' : 'ão'} de pagamentos em conta</strong>,`;
  }

  function handleApproveModalContent(isApproval) {
    if (isApproval) {
      return `
        <div class="subcontent">
          Caso não haja saldo disponível, não será possível processar os pagamentos.</br>
        </div>
        <div class="callout warning">
          Antes de concluir, certifique-se que os dados estão corretos.
          Redobre a atenção e <strong>cuidado com golpes</strong>.
        </div>
      `;
    }

    return '<div class="content mt-3">Tem certeza que deseja continuar?</div>';
  }

  function handleModal($element) {
    const currencyHelper = new CurrencyHelper();
    const isApprove = $element.data('action') === 'approve';
    const text = isApprove ? 'aprovar' : 'rejeitar';
    const value = $element.data('value') || total;
    const $modalContent = `
      <div class="swal-confirm-modal">
        <div class="content">
          Você está prestes a ${text} ${handleModalContent($element)}
          no valor total de <strong>${currencyHelper.format(value)}</strong>.
        </div>
        ${handleApproveModalContent(isApprove)}
      </div>
    `;
    $element.data('html', $modalContent);
  }

  function handleCheckbox() {
    const values = [];
    const records = [];

    $checkboxes.filter(':checked').each(function () {
      const tr = $(this).closest('tr');

      if (tr.find('#select_all').length) return;

      values.push(convertToNumber(tr.find('.JS-barcode-payment-value').data('value')));
      records.push(convertToNumber(tr.find('.JS-barcode-payment-record').data('value')));
    });

    total = convertToString(values.reduce((accumulated, value) => accumulated + value, INITIAL_VALUE));
    recordsTotal = convertToString(records.reduce((accumulated, value) => accumulated + value, INITIAL_VALUE));
    quantity = values.length;
    $buttonApprove().each((_, e) => handleModal($(e)));
    $buttonReject().each((_, e) => handleModal($(e)));
  }

  async function eventPropagation(event) {
    return new Promise((resolve) => {
      resolve(event.bubbles);
    });
  }

  $buttonApprove().each((_, e) => handleModal($(e)));
  $buttonReject().each((_, e) => handleModal($(e)));

  $checkboxes.on('change', async (event) => {
    const isEventPropagation = await eventPropagation(event);
    if (isEventPropagation) handleCheckbox();
  });
});
