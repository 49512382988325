const validateFields = ({
  $inputs,
  customErrorMessage,
  hideErrorFor = [],
  hintTagSelector,
  showSucess = true,
  validationsFor,
}) => {
  const applyInvalidHint = ($input, hint) => {
    $input.removeClass('is-valid').addClass('is-invalid');

    const $hint = hintTagSelector($input);

    $hint.empty().text(hint).addClass('is-invalid');
  };

  const removeInvalidHint = ($input) => {
    $input.removeClass('is-invalid');

    if (!$input.hasClass('select2') && showSucess) {
      $input.addClass('is-valid');
    }

    const $hint = hintTagSelector($input);

    $hint.empty().text('').removeClass('is-invalid');
  };

  const getHint = ($input, inputName) => {
    if (customErrorMessage) return customErrorMessage($input);

    return hideErrorFor.includes(inputName) ? '' : 'Preencha este campo';
  };

  const isFieldValid = ($input) => {
    const inputName = $input.attr('name');
    const inputValue = $input.val();

    let isValid = true;

    if (inputValue) {
      isValid = inputValue.constructor === Array
        ? inputValue && inputValue.length > 0
        : !!inputValue;
    } else {
      isValid = false;
    }

    const hint = getHint($input, inputName);

    if (validationsFor) {
      const validationMethod = validationsFor[inputName];

      if (validationMethod) {
        const { isValid: isInputValid, error } = validationMethod(inputValue);

        return { isValid: isInputValid, hint: error };
      }
    }

    return { isValid, hint };
  };

  const validate = ($input) => {
    const { isValid, hint } = isFieldValid($input);

    const inputName = $input.attr('name');

    if (isValid) {
      removeInvalidHint($input);
    } else if (!hideErrorFor.includes(inputName)) {
      applyInvalidHint($input, hint);
    }
  };

  const init = () => {
    $inputs.each((_, input) => validate($(input)));
  };

  init();
};


export default validateFields;
