function numericInput($) {
  const $numericInput = $('.JS-numeric-input').find('input');
  if (!$numericInput.length) return;

  $numericInput.each(function () {
    const $input = () => $(this);
    const $buttonMinus = $input().prev('.JS-button-minus');
    const $buttonPlus = $input().next('.JS-button-plus');
    const isInputDisabled = $input().is(':disabled');
    const minValue = $input().attr('min');
    const maxValue = $input().attr('max');
    const dataDefaultValue = $input().data('default-value');
    const isButtonMinusDisabled = () => !$input().val() || Number($input().val()) <= Number(minValue);
    const isButtonPlusDisabled = () => Number(maxValue) <= Number($input().val());
    const INCREMENT_VALUE = 1;
    const DECREMENT_VALUE = 1;

    function handleButtonDisabled() {
      $buttonMinus[`${isButtonMinusDisabled() ? 'add' : 'remove'}Class`]('disabled');
      $buttonPlus[`${isButtonPlusDisabled() ? 'add' : 'remove'}Class`]('disabled');
    }

    function handleDefaultValue() {
      if (!$input().val()) $input().val(dataDefaultValue);
    }

    function handleButton(type) {
      if (isInputDisabled) return;
      $input().val(function () {
        const types = {
          minus: Number(this.value) - INCREMENT_VALUE,
          plus: Number(this.value) + DECREMENT_VALUE,
        };
        return String(types[type]);
      });
      $input().trigger('input');
      handleButtonDisabled();
    }

    $buttonMinus.off('click');
    $buttonPlus.off('click');

    $input().on('input', handleButtonDisabled);

    $buttonMinus.on('click', (event) => {
      event.preventDefault();
      handleButton('minus');
    });

    $buttonPlus.on('click', (event) => {
      event.preventDefault();
      handleButton('plus');
    });

    handleButtonDisabled();
    handleDefaultValue();
  });
}

export default numericInput;

onLoad(($) => {
  numericInput($);
});
