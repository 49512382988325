const fillInvite = ({
  roles,
  ipBankAccountsTable,
}) => {
  if (roles.length <= 0) return;

  const $ipBankAccountsTable = ipBankAccountsTable;
  const currentRoles = roles;

  const $ipBankAccountsTableContainer = $ipBankAccountsTable.getContainer();

  const getCheckboxById = id => $ipBankAccountsTableContainer.find(`#child-checkbox-${id}`);

  const selectAccounts = () => {
    const ids = [];

    currentRoles.forEach((role) => {
      const $checkbox = getCheckboxById(role.ip_bank_account_id);

      ids.push(role.ip_bank_account_id);

      $checkbox.prop('checked', true);
    });

    $ipBankAccountsTable.setSelection(ids);
  };

  const initFiller = () => {
    selectAccounts();

    $('.JS-pre-invitation form.JS-user-info').trigger('submit');
  };

  initFiller();
};

export default fillInvite;
