import Validator from './Validator';
import CurrencyHelper from '../helper/currencyHelper';

onLoad(($) => {
  if (!$('.ds-barcode-payments').length) return;

  const currencyHelper = new CurrencyHelper();

  $('form.payment_barcode').on('submit', function (e) {
    e.preventDefault();
    const $form = $(this);
    const $inputBarcodes = $form.find('textarea:first');
    const $inputForceRequest = $form.find('#payment_barcode_force_request_after_limit_time');

    $.ajax({
      url: $form.attr('action'),
      type: 'post',
      data: {
        payment_barcode: {
          barcodes: $inputBarcodes.val(),
          force_request_after_limit_time: $inputForceRequest.is(':checked'),
        },
      },
      beforeSend: (xhr) => {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
    });
  });

  $('#bmp_barcode_payment_scheduled_at').on('change', () => {
    const $formSubmit = $(':submit');
    if ($formSubmit.attr('disabled')) {
      $formSubmit.removeAttr('disabled');
    }
  });

  $('#qitech_barcode_payment_scheduling_date').on('change', () => {
    const $formSubmit = $(':submit');
    if ($formSubmit.attr('disabled')) {
      $formSubmit.removeAttr('disabled');
    }
  });

  $('.JS-barcode-payment-value').on('input', (event) => {
    const currencyValue = event.target.value;
    const $barcodeText = $('.barcode-payment-text');
    const $confirmBtn = $('.JS-confirm-btn');
    const value = currencyHelper.unformat(currencyValue);
    $barcodeText.text(`R$ ${currencyValue}`);
    $confirmBtn[`${Number.isNaN(value) || value === 0 ? 'add' : 'remove'}Class`]('btn-disabled');
  });

  Validator.create(
    '.JS-validateBarcodePayment',
    '#barcodePaymentWarnings',
    '.JS-barcode-error-hint',
    '.barcode-payments-confirm-btn',
    {
      serverTime: $('#server-current-time').val(),
      isBusinessDay: $('#is-business-day').val(),
    },
  );
});
