import { PAGES } from '../helpers/navigation_helper';
import initAddAccountsIpBankAccountsTable from '../tables/add_account_table';
import onChangeVisibility from '../helpers/visibility_helper';

const initAddAccountForm = ({
  $,
  navigator,
  onAddAccountsCallback = () => { },
}) => {
  const $addAccount = $('.JS-user-add-account');

  if (!$addAccount.length) return;

  let $ipBankAccountsTable = null;

  // #region Event Handler Functions
  const onGoToRolesPage = () => {
    navigator.navigate(PAGES.invitation);
  };

  const onAddAccounts = () => {
    if (onAddAccountsCallback) {
      const currentSelection = $ipBankAccountsTable.getSelection();

      onAddAccountsCallback(currentSelection);
    }

    navigator.navigate(PAGES.invitation);
  };

  const onToggleFilter = (e) => {
    const $button = $(e.currentTarget);

    $button.find('.fa-filter').toggleClass('d-none');
    $button.find('.fa-times').toggleClass('d-none');
  };

  const hideFilterOnInit = () => {
    const $button = $('.filters-add-accounts-container #ds-btn-filter');
    const $collapser = $('.filters-add-accounts-container .multi-collapse');
    const $resetButton = $(".filters-add-accounts-container .filter-actions button[type='reset']");

    $button.find('.fa-filter').removeClass('d-none');
    $button.find('.fa-times').addClass('d-none');

    if ($collapser.hasClass('show')) {
      $collapser.removeClass('show');
      $resetButton.click();
    }
  };
  // #endregion Event Handler Functions

  // #region Init Table
  const initTable = () => {
    hideFilterOnInit();

    $ipBankAccountsTable = initAddAccountsIpBankAccountsTable($);
  };
  // #endregion Init Table

  // #region Init Visibility Listener
  const initListeners = () => {
    const secondStepElement = $addAccount[0];

    if (secondStepElement) {
      onChangeVisibility(secondStepElement, (visible) => {
        if (visible) initTable();
      });
    }
  };
  // #endregion Init Visibility Listener

  // #region Init Event Handlers
  const initOnClickEventHandlers = () => {
    $addAccount.find('.JS-add-account-return').on('click', onGoToRolesPage);
    $addAccount.find('.JS-confirm').on('click', onAddAccounts);
    $addAccount.find('#ds-btn-filter').on('click', onToggleFilter);
  };
  // #endregion Init Event Handlers

  // #region Init
  const init = () => {
    initListeners();
    initOnClickEventHandlers();
  };
  // #endregion Init

  init();
};

export default initAddAccountForm;
