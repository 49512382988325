const toggleMediaQueryForHubspotChat = () => {
  const $htmlTag = $('html')[0];
  const $hsIframeContainer = $('#hubspot-messages-iframe-container');
  if (!$hsIframeContainer.length) return;

  const toggleDefaultPositionForIframe = () => {
    if ($htmlTag.hasClass('hs-messages-widget-open')) {
      $hsIframeContainer.addClass('default');
    } else {
      $hsIframeContainer.removeClass('default');
    }
  };

  toggleDefaultPositionForIframe();

  $htmlTag.on('change', () => {
    toggleDefaultPositionForIframe();
  });
};

onLoad(() => toggleMediaQueryForHubspotChat());
