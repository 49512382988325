/* eslint-disable import/prefer-default-export */
export function select2Matcher($select) {
  $select.select2({
    theme: 'bootstrap4',
    matcher(params, data) {
      if (!params.term || !data.text) return data;
      const normalizeTerm = term => term.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').trim();
      const term = normalizeTerm(params.term);
      const text = normalizeTerm(data.text);
      if (text.includes(term)) return data;
      return null;
    },
  });
}

export function select2Empty($select) {
  const selectId = $select.attr('id');
  const handleEmptySelect = () => $(`#select2-${selectId}-container`).text('').trigger('change');
  if (!$select.val()) handleEmptySelect();

  $select.on('select2:select', function () {
    const selectedValue = $(this).val();
    const firstOptionValue = $(this).find('option:first').val();
    if (selectedValue === firstOptionValue) handleEmptySelect();
  });
}
