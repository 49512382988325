onLoad(($) => {
  const $enabledSwitch = $('.JS-enabled-switch');
  if (!$enabledSwitch.length) return;

  $enabledSwitch.each((_i, element) => {
    const $element = $(element);
    const isEnabled = $element.data('enabled');
    const $checkbox = $element.find("input[type='checkbox']");
    $checkbox.prop('checked', isEnabled);
  });
});
