import validateFields from '../helpers/validation_helper';

const initAutomaticRuleModal = ({
  $,
  onClose = () => { },
}) => {
  const $automaticRulesModal = $('#automaticRuleModal');

  if (!$automaticRulesModal.length) return;

  const $form = $('#JS-client-automatic-rule-form');

  const deletedAutomaticRules = [];

  // #region Form Handler Functions
  function getFieldIdFromFieldName(name) {
    const pattern = /\[(.*?)\]/g;
    const matches = [];

    let match;

    match = pattern.exec(name);

    while (match !== null) {
      matches.push(match[1]);
      match = pattern.exec(name);
    }

    if (matches.length >= 2) {
      return matches[1];
    }

    return null;
  }

  const getFieldIds = (key) => {
    const serializedData = $form.serializeArray();
    const ids = [];

    $.map(serializedData, (data) => {
      const name = data.name;

      if (name.includes(key)) {
        const id = getFieldIdFromFieldName(name);

        if (!ids.includes(id)) {
          ids.push(id);
        }
      }
    });

    return ids;
  };

  const getValueByName = (formValues = [], name) => formValues.filter(v => v.name === name).map(i => i.value);

  const getOccupationsName = fieldId => `user[role_automatic_rules_attributes][${fieldId}][occupations][]`;

  const getRoleKindName = fieldId => `user[role_automatic_rules_attributes][${fieldId}][role_kind_cd]`;

  const getFormDataByFieldName = (fieldName) => {
    const formValues = $form.serializeArray();
    const formValue = {};

    getFieldIds(fieldName).forEach((fieldId) => {
      const defaultValue = { id: null, role_kind_cd: null, occupations: [] };
      const occupationFieldName = getOccupationsName(fieldId);
      const occupations = getValueByName(formValues, occupationFieldName);
      const roleKind = getValueByName(formValues, getRoleKindName(fieldId))[0];
      const $occupationSelect = $(`select[name="${occupationFieldName}"]`);
      const ruleId = $occupationSelect.closest('tr').data('automatic-rule-id');

      let selectedOccupations = occupations;

      if (occupations.filter(occupation => occupation === '').length > 1) {
        selectedOccupations = occupations.splice(0, 1);
      } else if (occupations.filter(occupation => occupation === '').length === 1) {
        selectedOccupations = occupations.filter(occupation => occupation !== '');
      }

      if (!formValue[fieldId]) {
        formValue[fieldId] = defaultValue;
      }

      if (ruleId) {
        formValue[fieldId].id = ruleId;
      }

      formValue[fieldId].role_kind_cd = roleKind;

      selectedOccupations.forEach((occupation) => {
        formValue[fieldId].occupations.push(occupation);
      });

      const canDestroy = deletedAutomaticRules.includes(fieldId);

      const destroyKey = '_destroy';

      formValue[fieldId][destroyKey] = canDestroy;
    });

    return formValue;
  };
  // #endregion Form Handler Functions

  // #region Form Validation Functions
  const isFormValid = () => {
    const $inputs = $form.find(':input');

    let isValid = true;

    $inputs.each((_, input) => {
      const $input = $(input);
      const hasInvalidClass = $input.hasClass('is-invalid');

      if (hasInvalidClass) {
        isValid = false;
      }
    });

    return isValid;
  };

  const validateForm = () => {
    const $inputs = $form.find('select');
    const getCustomErrorMessage = $input => ($input.hasClass('select2')
      ? 'Preencha este campo'
      : 'Perfil não está incluído na lista e Perfil não pode ficar em branco');
    const getHintTagSelector = $input => $input.parent().siblings('.invalid-feedback');

    validateFields({
      $inputs,
      showSucess: false,
      customErrorMessage: getCustomErrorMessage,
      hintTagSelector: getHintTagSelector,
    });
  };
  // #endregion Form Validation Functions

  // #region Event Handler Functions
  const onConfirmButtonClick = () => {
    if (!onClose) return;

    validateForm();

    if (isFormValid()) {
      onClose(getFormDataByFieldName('user[role_automatic_rules_attributes]'));

      $automaticRulesModal.modal('hide');
    }
  };

  const onRemove = (e) => {
    const $button = $(e.currentTarget);
    const $input = $button.siblings().filter(':input');
    const inputName = $input.attr('name');
    const id = getFieldIdFromFieldName(inputName);

    deletedAutomaticRules.push(id);
  };
  // #endregion Event Handler Functions

  // #region Event Handler
  const initClickEventHandlers = () => {
    $automaticRulesModal.find('.modal-footer button').on('click', onConfirmButtonClick);
    $automaticRulesModal.find('.remove_fields').on('click', onRemove);
  };
  // #endregion Event Handler

  // #region Init
  const init = () => {
    initClickEventHandlers();

    $automaticRulesModal.find('.modal-footer button').click();
  };
  // #endregion Init

  init();
};

export default initAutomaticRuleModal;
