onLoad(($) => {
  function copyToClipboard(text) {
    const $temp = $('<input>');
    $('body').append($temp);
    $temp.val(text.trim()).select();
    document.execCommand('copy');
    $temp.remove();
  }

  function confirmCopySwal(message) {
    Swal.fire({
      title: message,
      icon: 'info',
      timer: 1500,
      showCancelButton: false,
      showConfirmButton: false,
    });
  }

  function confirmCopySwalTop(message) {
    Swal.fire({
      title: message,
      icon: 'success',
      confirmButtonText: 'OK',
      showCancelButton: false,
      toast: true,
      position: 'top-end',
      timer: 10000,
    });
  }

  $('.JS-clipboard-icon').on('click', function () {
    copyToClipboard($(this).text());
    confirmCopySwal($(this).data('message'));
  })
    .hover(function () {
      $(this).find('.fa-copy').show();
    }, function () {
      $(this).find('.fa-copy').hide();
    });

  $('.JS-clipboard-hidden-value').on('click', function () {
    copyToClipboard($(this).data('copy'));
    confirmCopySwal($(this).data('message'));
  });

  $('.JS-clipboard-btn').on('click', function () {
    copyToClipboard($(this).data('copy'));
    confirmCopySwalTop($(this).data('message'));
  });
});
