import { select2Matcher } from '../../helper/select2';

onLoad(($) => {
  const $messagingRuleTable = $('#messagingRuleTable');
  if (!$messagingRuleTable.length) return;

  const $messagingRuleSelector = $('.JS-messaging-rule-selector');
  const $editMessagingRuleBtn = $('#edit-messaging-rule-btn');
  const $toggleMessagingRuleModal = $('.toggle-messaging-rule-modal');
  const $indexDetailsBtn = $('.index-details-btn');
  const $wrapEditMessagingRule = $('.wrap-edit-messaging-rule');
  const $calloutMessagingRuleEmail = $('.JS-callout-messaging-rule-email');
  const $modalMessagingRule = $('#messaginRuleModal');
  const WIDTH_MOBILE = 640;
  let wrapEditMessagingRuleTopValue;

  function eventTemplate(uuid, eventData) {
    const previewEmail = () => {
      let preview = '';
      if (eventData.communication_format_cd === 'email') {
        preview = `
          <tr>
            <th>Prévia do e-mail</th>
            <td>
              <a href="/messaging_rules/${uuid}/email_preview/${eventData.kind_cd}" target="_blank">
                Abrir modelo do e-mail a ser enviado.
              </a>
            </td>
          </tr>
        `;
      }
      return preview;
    };

    const template = `
      <div class="title">Evento</div>
      <table id="messaging-rule-info-table">
        <tr>
          <th>Formato de Comunicação</th>
          <td>${eventData.translated_communication_format}</td>
        </tr>
        <tr>
          <th>Tipo</th>
          <td class="kind-text">${eventData.translated_kind}</td>
        </tr>
        <tr>
          <th>Dia / Disparo</th>
          <td>
            ${Math.abs(eventData.days)} dias / 
            <span class="trigger-time-text">${eventData.translated_trigger_time}</span>
          </td>
        </tr>
        ${previewEmail()}
      </table>
    `;
    return template;
  }

  function setDetailsInfo(data) {
    const infoList = ['label', 'emitter_name', 'emitter_email', 'boleto_registration'];

    infoList.forEach((info) => {
      const infoDiv = $(`.messaging-rule-${info}`);
      const infoText = data[0][info];
      if (infoText.length) {
        infoDiv.removeClass('d-none');
        infoDiv.find('span').text(infoText);
        return;
      }
      infoDiv.addClass('d-none');
    });
  }

  function handleMessagingRuleError() {
    $messagingRuleSelector.val('');
    $editMessagingRuleBtn.addClass('d-none');
    $toggleMessagingRuleModal.addClass('d-none');
    $messagingRuleTable.empty();
    $('.messaging-rule-boleto_registration').addClass('d-none');
  }

  function handleMessagingRuleSuccess(data) {
    $messagingRuleTable.empty();
    const hasChargeOpened = data[0].has_charge_opened;

    if (data.length !== 1 || !data[0]) {
      handleMessagingRuleError();
      return;
    }

    if (data) {
      const emailConfigs = data[0].email_configs;
      if (!emailConfigs) {
        $messagingRuleTable.append('<div class="callout warning">Eventos não encontrados.</div>');
      } else if (!emailConfigs.length) {
        $messagingRuleTable.append('<div class="no-event">Nenhum evento configurado.</div>');
      } else {
        emailConfigs.forEach((emailConfig) => {
          $messagingRuleTable.append(eventTemplate(data[0].uuid, emailConfig));
        });
      }

      $editMessagingRuleBtn.prop('href', `/messaging_rules/${data[0].uuid}/edit`);
      $editMessagingRuleBtn[`${hasChargeOpened ? 'add' : 'remove'}Class`]('btn-disabled');
      $wrapEditMessagingRule.tooltip(`${hasChargeOpened ? 'enable' : 'disable'}`);
      $toggleMessagingRuleModal.removeClass('d-none');
      $editMessagingRuleBtn.removeClass('d-none');
      setDetailsInfo(data);
      const emailRequired = data[0].email_required;
      $calloutMessagingRuleEmail[`${emailRequired && $calloutMessagingRuleEmail.length
        ? 'remove' : 'add'}Class`]('d-none');
    }
  }

  function findMessagingRule(id) {
    if (!id) {
      handleMessagingRuleError();
      return;
    }

    $.ajax({
      url: `/messaging_rules?q[id_eq]=${id}`,
      type: 'get',
      dataType: 'json',
      success: (data) => {
        handleMessagingRuleSuccess(data);
      },
      error: () => { handleMessagingRuleError(); },
    });
  }

  const handleMessagingRuleChange = () => {
    if (!$messagingRuleSelector.length) return;

    const $messagingRuleId = $messagingRuleSelector.val();

    findMessagingRule($messagingRuleId);
  };

  handleMessagingRuleChange();

  $messagingRuleSelector.on('change', () => {
    handleMessagingRuleChange();
  });

  $indexDetailsBtn.on('click', (element) => {
    const $messagingRuleId = element.target.id;
    findMessagingRule($messagingRuleId);
  });

  $(document).ready(() => select2Matcher($messagingRuleSelector));

  if (!($(window).width() <= WIDTH_MOBILE)) return;

  $modalMessagingRule.on('show.bs.modal', function () {
    $(this).prepend($wrapEditMessagingRule);
  });

  $modalMessagingRule.on('scroll', function () {
    if (!wrapEditMessagingRuleTopValue) {
      wrapEditMessagingRuleTopValue = $wrapEditMessagingRule.css('top');
    }
    const scrollTop = $(this).scrollTop();
    const composeTopValue = Number(wrapEditMessagingRuleTopValue.replace('px', '')) + scrollTop;
    $wrapEditMessagingRule.css('top', composeTopValue);
  });
});
