import { CONTEXT, UserManagementNavigator } from './helpers/navigation_helper';
import { fetchUserProfiles, toggleBtnLoadIndicator, validateUserForm } from './async_actions';
import fillInvite from './helpers/form_filler_helper';
import initAutomaticRuleModal from './forms/automatic_rule_form';
import initInvitationPage from './forms/invite_form';
import initPreInvitationIpBankAccountsTable from './tables/pre_invitation_accounts_table';
import initUserFormPage from './forms/user_form';

const initUserInvitationJourney = ($) => {
  const invitePageSelector = '.JS-invite-user';

  const $inviteUserPage = $(invitePageSelector);

  if (!$inviteUserPage.length) return;

  let $ipBankAccountsTable = null;
  let inviteForm = null;
  let inviteNavigator = null;
  let roleAutomaticRules = [];
  let selectOptions = null;
  let userForm = null;

  // #region Private Functions
  const showErrorModal = () => {
    Swal.fire({
      text: 'Algumas regras de usuário estão inválidas',
      icon: 'error',
      customClass: { confirmButton: 'close-error-modal' },
    });
  };

  const buildRolesObject = (userRoles = []) => {
    const rolesObject = {};

    userRoles.forEach(({ ipBankAccountId, roleId }, index) => {
      const idWithoutTimestamp = ipBankAccountId.split('_')[0];

      rolesObject[index] = {
        ip_bank_account_id: idWithoutTimestamp,
        kind_cd: roleId,
      };
    });

    return rolesObject;
  };

  const buildAutomaticRolesObject = (automaticRules = {}) => {
    const automaticRulesObject = {};
    const keys = Object.keys(automaticRules);

    keys.forEach((key, index) => {
      const { role_kind_cd: roleKind, occupations } = automaticRules[key];

      automaticRulesObject[index] = { role_kind_cd: roleKind, occupations };
    });

    return automaticRulesObject;
  };

  const getUserInfo = () => {
    if (!userForm) return undefined;

    const {
      document_number: documentNumber,
      email,
      name,
      onboarding_phone: onboardingPhone,
    } = userForm.getValue();

    return {
      documentNumber, email, name, onboardingPhone,
    };
  };

  const getUserRoles = () => {
    if (!inviteForm) return [];

    const { roles: selectedRoles, isValid } = inviteForm.getValue();

    if (isValid) {
      return { isValid, roles: buildRolesObject(selectedRoles) };
    }

    showErrorModal();

    return { isValid, roles: [] };
  };

  const getInviteObject = () => {
    const {
      documentNumber, email, name, onboardingPhone,
    } = getUserInfo();
    const { isValid, roles } = getUserRoles();

    const inviteObject = {
      email,
      onboarding_phone: onboardingPhone,
      person_attributes: {
        name,
        document_number: documentNumber.replace(/[^a-zA-Z0-9 ]/g, ''),
      },
      roles_attributes: roles,
      role_automatic_rules_attributes: roleAutomaticRules,
    };

    return { isValid, invite: inviteObject };
  };

  const setHiddenFormValues = ($hiddenForm, invite) => {
    const stringifiedInvite = JSON.stringify(invite);
    const $hiddenInput = $hiddenForm.find('input[name="user"]');

    if (!$hiddenInput.length) return;

    $hiddenInput.val(stringifiedInvite);
  };
  // #endregion Private Functions

  // #region Async Form Validation
  const asyncFormValidation = ({ onError = () => { }, onSuccess = () => { } }) => {
    const clientId = $('#client_id').val();
    const {
      documentNumber, email, name, onboardingPhone,
    } = getUserInfo();
    const params = {
      clientId,
      user: {
        email,
        onboarding_phone: onboardingPhone,
        person_attributes: {
          name,
          document_number: documentNumber.replace(/[^a-zA-Z0-9 ]/g, ''),
        },
        roles_attributes: [],
        role_automatic_rules_attributes: [],
      },
    };

    const $submitButton = $inviteUserPage.find('.JS-continue-button');

    toggleBtnLoadIndicator($submitButton, 'goToSecondStep', true);

    const onErrorFn = (response) => {
      if (onError) onError(response);
      toggleBtnLoadIndicator($submitButton, 'goToSecondStep', false);
    };
    const onSuccessFn = (response) => {
      if (onSuccess) onSuccess(response);
      toggleBtnLoadIndicator($submitButton, 'goToSecondStep', false);
    };

    validateUserForm({
      $, params, onError: onErrorFn, onSuccess: onSuccessFn,
    });
  };
  // #endregion Async Form Validation

  // #region Event Handler Functions
  const onCloseAutomaticRulesModal = (rules) => {
    const automaticRules = buildAutomaticRolesObject(rules);

    roleAutomaticRules = automaticRules;
  };

  const onClickInviteUser = (e) => {
    const { isValid, invite } = getInviteObject();

    if (!isValid) return;

    const $button = $(e.currentTarget);

    $button.prop('disable', true);

    const $hiddenForm = $inviteUserPage.find('#JS-invite-hidden-form');

    setHiddenFormValues($hiddenForm, invite);

    $hiddenForm.find('button[type="submit"]').click();
  };

  const onBeforeFetchUserProfilesFinalize = (response) => {
    if (response) selectOptions = response;

    inviteForm = initInvitationPage({
      $,
      ipBankAccountsTable: $ipBankAccountsTable,
      navigator: inviteNavigator,
      selectOptions,
    });
  };

  const onBeforeInitInviteForm = () => {
    fetchUserProfiles({
      $,
      onSuccess: onBeforeFetchUserProfilesFinalize,
      onError: onBeforeFetchUserProfilesFinalize,
    });
  };

  const onSubmitInviteError = () => {
    const userRoles = $('#user_roles').val();

    if (!userRoles) return;

    const parsedUserRoles = JSON.parse(userRoles);

    if (parsedUserRoles && !parsedUserRoles.length) return;

    fillInvite({
      roles: parsedUserRoles,
      ipBankAccountsTable: $ipBankAccountsTable,
    });
  };
  // #endregion Event Handler Functions

  // #region Init Event Handlers
  const initClickEventHandlers = () => {
    $inviteUserPage.find('.JS-send-invite').on('click', onClickInviteUser);
  };
  // #endregion Init Event Handlers

  // #region Init Forms
  const initForms = () => {
    inviteNavigator = new UserManagementNavigator({
      context: CONTEXT.invite,
      parentContainerSelector: invitePageSelector,
    });

    userForm = initUserFormPage({
      $,
      ipBankAccountsTable: $ipBankAccountsTable,
      navigator: inviteNavigator,
      onBeforeProcceed: asyncFormValidation,
    });
  };
  // #endregion Init Forms

  // #region Init
  const init = () => {
    initClickEventHandlers();

    $ipBankAccountsTable = initPreInvitationIpBankAccountsTable({
      $,
      onListAccounts: onSubmitInviteError,
    });

    initForms();
    onBeforeInitInviteForm();
    initAutomaticRuleModal({ $, onClose: onCloseAutomaticRulesModal });
  };
  // #endregion Init

  init();
};

onLoad($ => initUserInvitationJourney($));
